import React from "react";
import "./Categories.scss";
import { Link } from "react-router-dom";

const Categories = () => {
  return (
    <div className="categories">
      <div className="col">
        <div className="row">
          <img src="\img\t-shirtManJacket.jpg" alt="" />
          <button>
            <Link className="link" to="/products/1">
              Men
            </Link>
          </button>
        </div>
        <div className="row">
          {" "}
          <img src="\img\t-shirtWomanLarge.jpg" alt="" />
          <button>
            <Link className="link" to="/products/2">
              Women
            </Link>
          </button>
        </div>
      </div>
      <div className="col">
        <div className="row">
          <img src="\img\t-shirtManLarge2.jpg" alt="" />
          <button>
            <Link className="link" to="/products/2">
              New Season
            </Link>
          </button>
        </div>
      </div>

      <div className="col col-l">
        <div className="row">
          <div className="col">
            <div className="row">
              {" "}
              <img src="\img\menwatches.jpg" alt="" />
              <button>
                <Link className="link" to="/products/1">
                  Accessories
                </Link>
              </button>
            </div>
          </div>
          <div className="col">
            <div className="row">
              {" "}
              <img src="\img\t-shirts.jpg" alt="" />
              <button>
                <Link className="link" to="/products/1">
                  Sale
                </Link>
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          {" "}
          <img src="\img\t-shirtManLarge.jpg" alt="" />
          <button>
            <Link className="link" to="/products/1">
              Last Season
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Categories;
